export const INITIAL_STATE = {
  pipesFeatureEnabledA: false,
  gapA: 120,
  gravityA: 9.8,

  pipesFeatureEnabledB: true,
  gapB: 120,
  gravityB: 10,

  step: 0,
  controlSets: 1,
  playedLevel: -1,
  nextEnabled: false,

  /*
  step: 7,
  controlSets: 1, // Must reduce control sets down
  playedLevel: 3,
  nextEnabled: true, // Must enable next
  codeRevision: 1,
  */
}

export const STEP_DEFAULTS = [
  {},
  {},
  {},
  {
    controlSets: 1,
  },
  {
    controlSets: 2,
  },
  {},
  {},
  {},
  {},
  {},
  {},
  {},
]

export const events = {
  STEP1_WELCOME_VIEWED: 'FS FlappyBird Demo Step1 Welcome Viewed',
  STEP2_ADD_A_FEATURE_VIEWED: 'FS FlappyBird Demo Step2 Add a Feature Viewed',
  STEP3_CONFIGURE_VARIABLES_VIEWED: 'FS FlappyBird Demo Step3 Configure Variables Viewed',
  STEP4_SETUP_EXPERIMENT_VIEWED: 'FS FlappyBird Demo Step4 Setup Experiment Viewed',
  STEP5_RUN_EXPERIMENT_VIEWED: 'FS FlappyBird Demo Step5 Run Experiment Viewed',
  STEP6_ANALYZE_RESULTS_VIEWED: 'FS FlappyBird Demo Step6 Analyze Results Viewed',
  STEP7_CONGRATS_VIEWED: 'FS FlappyBird Demo Step7 Congrats Viewed',
  FEATURE_TOGGLED: 'FS FlappyBird Demo Feature Toggled',
  SLIDER_CHANGED: 'FS FlappyBird Demo Slider Changed',
  VARIATION_ADDED: 'FS FlappyBird Demo Variation Added',
  VARIATION_SWITCHED: 'FS FlappyBird Demo Variation Switched',
  EXPERIMENT_RAN: 'FS FlappyBird Demo Experiment Ran',
  WINNER_LAUNCHED: 'FS FlappyBird Demo Winner Launched',
  SEE_DOCS: 'FS FlappyBird Demo See Docs Clicked',
  CREATE_ACCOUNT: 'FS FlappyBird Demo Create Account Clicked',
  DEMO_RESET: 'FS FlappyBird Demo Demo Reset',
}

export const TRANSLATIONS = {
  'step-1-title': {
    'en': `Welcome`,
    'ja': 'ようこそ',
  },
  'step-1-text': {
    'en': `In this tutorial, you'll see what it's like to use Optimizely Full Stack to experiment on your application: FlappyBird. Before you make any changes, try playing your app. It's not very exciting, right?`,
    'ja': 'このチュートリアルでは、FlappyBirdというゲームアプリでどのようにOptimizely Full Stackを使ってテストできるかが体験できます。先ずは何も変化を加えずにFlappyBirdを現状のまま遊んでみてください！',
  },
  'step-2-title': {
    'en': `Add a Feature`,
    'ja': '次に障害物を足してみましょう',
  },
  'step-2-text': {
    'en': `Your team already added a pipes feature to make the game more interesting. With Optimizely Full Stack, you can deploy that feature remotely. Try enabling the pipes feature and replaying the game.`,
    'ja': '加えた障害物は事前にあなたのチームがゲームの面白くする為に準備した機能です。Optimizely Full Stackを使えば、遠隔でその機能を加えることが可能です。機能をONにした状態で再度プレイしてみてください。',
  },
  'step-3-title': {
    'en': `Configure Variables`,
    'ja': '変数を編集・設定する',
  },
  'step-3-text': {
    'en': `In addition to toggling features, you can also configure remote variables. Using Optimizely Full Stack, try increasing the gravity value for the game and replaying.`,
    'ja': '機能トグルの補足情報として、遠隔で変数の編集や設定も可能です。Optimizely Full Stackを使って今度は重力を加え再度遊んでみましょう。',
  },
  'step-4-title': {
    'en': `Setup an Experiment`,
    'ja': 'テストを作成・設定する',
  },
  'step-4-text': {
    'en': `Not sure what the best configuration is? With Optimizely Full Stack, you can create multiple different versions of your application to run a live A/B test on your users to see which configuration is best.`,
    'ja': 'どの重力に設定するのが一番効果出そうかわからないですよね？Optimizelyのフルスタックでは複数バリエーションを作成しABテストを走らせることが可能で、どの設定が一番効果的だったかの結果を出します。',
  },
  'step-5-title': {
    'en': `Run the Experiment`,
    'ja': 'テストを実施する',
  },
  'step-5-text': {
    'en': `With different versions of your application, you can run a live experiment on real traffic to see which version of the app is more engaging.`,
    'ja': '複数のアプリのバージョン中で実際のユーザーにテストを実施し、どのアプリのバージョンで利用率が上がったか検証可能です。',
  },
  'step-6-title': {
    'en': `Analyze the Results`,
    'ja': '結果を分析する',
  },
  'step-6-text': {
    'en': `As an experimentation platform, Optimizely provides statistical significance for the metrics that you care about the most. Launch the winning variation.`,
    'ja': 'テストプラットホームとして、Optimizelyはあなたが注視している指標の統計的有意性を測定します。勝利したバリエーションを展開しましょう。',
  },
  'step-7-title': {
    'en': `Congrats`,
    'ja': 'おめでとうございます！',
  },
  'step-7-text': {
    'en': `You removed the digital guesswork from product development and now understand the basics of Optimizely Full Stack. Time to create an account and use it for real.`,
    'ja': '製品開発からデジタルの当て推量を取り除き、Optimizelyのフルスタックの基本を理解しました。アカウントを作成して実際に使ってみる時です！',
  },
  'step-8-title': {
    'en': `See the code`,
    'ja': 'コードを見ましょう',
  },
  'step-8-text': {
    'en': `Let's take a look at what it takes to install Optimizely by first looking at our FlappyBird code to the right. Notice how the pipes and gravity settings are controlled in code, then see how to install Optimizely to control them.`,
    'ja': 'Optimizelyをあなたのアプリへインストールする方法を実装済みのFlappyBirdアプリで確認して見ましょう。先ほど加えた障害物や重力設定の機能がコード内で制御・管理されているのが分かります。その上でOptimizelyのインストール方法も合わせて確認しましょう。',
  },
  'step-9-title': {
    'en': `Import the SDK`,
    'ja': 'SDKをインポート',
  },
  'step-9-text': {
    'en': `The first step to install Optimizely is importing the Optimizely SDK in your codebase.`,
    'ja': '先ずは、OptimizelyのSDKをインポートするところから始めましょう。',
  },
  'step-10-title': {
    'en': `Connect the SDK`,
    'ja': 'SDKを連携',
  },
  'step-10-text': {
    'en': `The next step is to connect the SDK to your Optimizely UI via an SDK key. Optimizely uses the SDK key to download all the feature flag and variable data in the form of a JSON datafile from Optimizely's CDN so the SDK operates quickly in-memory.`,
    'ja': '次に、SDKのキーを使ってSDKをOptimizely管理画面と連携させます。OptimizelyはSDKのキーを使ってJSON形式で機能フラグやバリエーションのデータをOptimizelyCDNよりダウンロードします。',
  },
  'step-11-title': {
    'en': `Add a Feature Flag`,
    'ja': '機能フラグを追加',
  },
  'step-11-text': {
    'en': `Once the SDK is connected to the UI, you can then add a feature flag using the 'isFeatureEnabled' method and the name of your feature. Toggle the feature and see the value change in the comment.`,
    'ja': `Optimizely管理画面とSDKの連携が完了後、'isFeatureEnabled'メソッドを使って機能フラグを追加し機能を命名できます。機能を切り替えてコメント内の値が変わるのを確認してみましょう。`,
  },
  'step-12-title': {
    'en': `Add a Feature Variable`,
    'ja': '変数を加える',
  },
  'step-12-text': {
    'en': `You can also use the 'getFeatureVariable' method to get the value of variables defined in Optimizely. Change the variable and see the value change in the comment.`,
    'ja': `Optimizely既定されている変数の値を取得する為に 'getFeatureVariable' メソッドも使用可能です。変数を変更してコメント内の値が変わるのを確認してみましょう。`,
  },
  'step-13-title': {
    'en': `Track Events`,
    'ja': 'イベント計測',
  },
  'step-13-text': {
    'en': `Using the 'track' method of the Optimizely SDK, you can track events you care about. With integrations, you can also send Optimizely event data to analytics platforms you already use.`,
    'ja': `OptimizelySDKの 'track' メソッドを使うとイベントの計測が可能です。また、アナリティクスツールとの連携を実装後Optimizelyのイベントデータをアナリティクスへ転送することもできます。`,
  },
  'step-14-title': {
    'en': `Experiment on Users`,
    'ja': 'ユーザーに向けた実験',
  },
  'step-14-text': {
    'en': `By passing in an identifier for the user, Optimizely can bucket different users into different variations in order to run an experiment. Bucketing is done deterministically and in-memory with hashing so it's fast and consistent.`,
    'ja': '各ユーザーへ識別子を振られてOptimizelyへ識別子情報を渡せば、Optimizelyでバリエーション毎のオーディエンスも管理可能です。オーディエンスのバケットは確定的に行われ、メモリ内でハッシュを使用して行われるため、高速で一貫性があります。',
  },
  'step-15-title': {
    'en': `Control the Experience`,
    'ja': '実験をコントロール',
  },
  'step-15-text': {
    'en': `Now that you have installed Optimizely, you can control the experience without any additional changes to the code.`,
    'ja': 'Optimizelyのインストールが完了すると、追加コーディングなしで実験の制御・管理が可能です。',
  },
  'step-16-title': {
    'en': `Congrats`,
    'ja': 'おめでとうございます！',
  },
  'step-16-text': {
    'en': `You now understand how to install Optimizely into your codebase. If you still want to learn more, checkout our documentation and FAQ.`,
    'ja': 'これで、Optimizelyをコードベースにインストールする方法を理解できました。さらに詳細を知りたい場合は、ドキュメントとFAQをご覧ください。',
  },
  'next': {
    'en': 'Next',
    'ja': '次へ',
  },
  'back': {
    'en': 'Back',
    'ja': '戻る',
  },
  'add-a-feature': {
    'en': 'Add a feature',
    'ja': '次に障害物を足してみましょう',
  },
  'turn-on-pipes': {
    'en': 'Turn on pipes',
    'ja': '障害物を加える'
  },
  'try-playing-with-pipes': {
    'en': 'Try playing with pipes',
    'ja': '障害物ありで遊ぶ'
  },
  'try-playing-with-no-pipes': {
    'en': 'Try playing with no pipes',
    'ja': '先ずはゲームの背景を障害物なしで遊んでみてください'
  },
  'add-a-variable': {
    'en': 'Add a variable',
    'ja': '変数を足す',
  },
  'change-gravity': {
    'en': 'Change gravity',
    'ja': '重力を変更する',
  },
  'try-with-different-gravity': {
    'en': 'Try with different gravity',
    'ja': '別の重力にしてみましょう',
  },
  'add-a-variation': {
    'en': 'Add a variation',
    'ja': 'バリエーションを追加',
  },
  'add-variation-btn': {
    'en': 'Add Variation',
    'ja': 'バリエーションを追加',
  },
  'setup-an-experiment': {
    'en': 'Setup an experiment',
    'ja': 'テストを作成・設定する',
  },
  'switch-variation': {
    'en': 'Switch variation',
    'ja': 'バリエーションを変更',
  },
  'try-playing-this-variation': {
    'en': 'Try playing this variation',
    'ja': 'このバリエーションで遊んでみましょう',
  },
  'see-the-results': {
    'en': 'See the results',
    'ja': '結果を見る',
  },
  'launch-winning-variation': {
    'en': 'Launch winning variation',
    'ja': '勝ちバリエーションを展開しましょう。',
  },
  'optimizely-application': {
    'en': 'Optimizely Application',
    'ja': 'Optimizely Application'
  },
  'variation-a-switcher': {
    'en': 'Variation A',
    'ja': 'バリエーションA'
  },
  'variation-b-switcher': {
    'en': 'Variation B',
    'ja': 'バリエーションB'
  },
  'run-an-experiment-btn': {
    'en': 'Run an Experiment',
    'ja': 'テストを実施しましょう',
  },
  'rollout-winner-btn': {
    'en': 'Rollout Winner',
    'ja': '勝ちバリエーションをロールアウトしましょう',
  },
  'see-code-btn': {
    'en': 'See Code',
    'ja': 'コードを見る',
  },
  'create-account': {
    'en': 'Create account',
    'ja': 'アカウント作成',
  },
  'create-an-account-btn': {
    'en': 'Create an Account',
    'ja': 'アカウント作成',
  },
  'reset-demo-btn': {
    'en': 'Reset Demo',
    'ja': 'デモをリセット',
  },
  'see-faq-btn': {
    'en': 'See FAQ',
    'ja': 'FAQを見る',
  },
  'run-an-experiment': {
    'en': 'Run an experiment',
    'ja': 'テストを実施しましょう',
  },
  'install-optimizely': {
    'en': 'Install Optimizely',
    'ja': 'Optimizelyをインストール',
  },
  'connect-the-sdk': {
    'en': 'Connect the SDK',
    'ja': 'SDKを連携',
  },
  'add-a-feature-flag': {
    'en': 'Add a Feature Flag',
    'ja': '機能フラグを追加',
  },
  'add-a-feature-variable': {
    'en': 'Add a Feature Variable',
    'ja': '変数を加える',
  },
  'track-events': {
    'en': 'Track Events',
    'ja': 'イベント計測',
  },
  'experiment': {
    'en': 'Experiment',
    'ja': '実験',
  },
  'use-optimizely': {
    'en': 'Use Optimizely',
    'ja': 'Optimizelyを使う',
  },
  'get-started': {
    'en': 'Get Started',
    'ja': '始める',
  },
}

const TOOLTIP_OFFSETS = {
  'add-a-feature': {
    'en': {
      'x': '230px',
      'y': '',
    },
    'ja': {
      'x': '120px',
      'y': '',
    },
  },
  'turn-on-pipes': {
    'en': {
      'x': '75px',
      'y': '0px',
    },
    'ja': {
      'x': '80px',
      'y': '0px',
    },
  },
  'launch-winning-variation': {
    'en': {
      'x': '-61px',
      'y': '7px',
    },
    'ja': {
      'x': '-61px',
      'y': '7px',
    },
  },
  'setup-an-experiment': {
    'en': {
      'x': '155px',
      'y': '237px',
    },
    'ja': {
      'x': '131px',
      'y': '238px',
    },
  },
  'see-the-results': {
    'en': {
      'x': '192px',
      'y': '469px',
    },
    'ja': {
      'x': '216px',
      'y': '469px',
    },
  },
  'create-account': {
    'en': {
      'x': '143px',
      'y': '9px',
    },
    'ja': {
      'x': '143px',
      'y': '9px',
    },
  },
  'try-playing-with-no-pipes': {
    'en': {
      'x': '72px',
      'y': '',
    },
    'ja': {
      'x': '',
      'y': '',
    },
  },
  'try-playing-with-pipes': {
    'en': {
      'x': '80px',
      'y': '',
    },
    'ja': {
      'x': '93px',
      'y': '',
    },
  },
  'try-with-different-gravity': {
    'en': {
      'x': '72px',
      'y': '',
    },
    'ja': {
      'x': '69px',
      'y': '',
    },
  },
  'add-a-variable': {
    'en': {
      'x': '226px',
      'y': '',
    },
    'ja': {
      'x': '247px',
      'y': '',
    },
  },
  'change-gravity': {
    'en': {
      'x': '150px',
      'y': '-30px',
    },
    'ja': {
      'x': '150px',
      'y': '-31px',
    },
  },
  'add-a-variation': {
    'en': {
      'x': '221px',
      'y': '',
    },
    'ja': {
      'x': '177px',
      'y': '',
    },
  },
  'switch-variation': {
    'en': {
      'x': '-70px',
      'y': '19px',
    },
    'ja': {
      'x': '-70px',
      'y': '19px',
    },
  },
  'try-playing-this-variation': {
    'en': {
      'x': '75px',
      'y': '',
    },
    'ja': {
      'x': '33px',
      'y': '',
    },
  },
  'run-an-experiment': {
    'en': {
      'x': '196px',
      'y': '',
    },
    'ja': {
      'x': '163px',
      'y': '',
    },
  },
  'install-optimizely': {
    'en': {
      'x': '207px',
      'y': '',
    },
    'ja': {
      'x': '151px',
      'y': '',
    },
  },
  'connect-the-sdk': {
    'en': {
      'x': '206px',
      'y': '',
    },
    'ja': {
      'x': '247px',
      'y': '',
    },
  },
  'add-a-feature-flag': {
    'en': {
      'x': '197px',
      'y': '',
    },
    'ja': {
      'x': '203px',
      'y': '',
    },
  },
  'add-a-feature-variable': {
    'en': {
      'x': '172px',
      'y': '',
    },
    'ja': {
      'x': '231px',
      'y': '',
    },
  },
  'track-events': {
    'en': {
      'x': '235px',
      'y': '',
    },
    'ja': {
      'x': '232px',
      'y': '',
    },
  },
  'experiment': {
    'en': {
      'x': '247px',
      'y': '',
    },
    'ja': {
      'x': '280px',
      'y': '',
    },
  },
  'use-optimizely': {
    'en': {
      'x': '220px',
      'y': '',
    },
    'ja': {
      'x': '206px',
      'y': '',
    },
  },
  'get-started': {
    'en': {
      'x': '243px',
      'y': '',
    },
    'ja': {
      'x': '273px',
      'y': '',
    },
  },
}


const TEXT_KEYS = Object.keys(TRANSLATIONS);

function getTranslatedText(id, language, debug) {
  var translation = '';
  if (TEXT_KEYS.includes(id)) {
    if (TRANSLATIONS[id][language]) {
      return String(TRANSLATIONS[id][language])
    } else {
      console.warn(`[Flappy Bird Demo] text for ${id} has no translation for ${language}`)
      return String(TRANSLATIONS[id]['en'])
    }
  }
  console.warn(`[Flappy Bird Demo] no text exists for ${id}`)
  return String(undefined)
}

function getOffset(id, direction) {
  var urlParams = new URLSearchParams(window.location.search);
  var language = urlParams.get('language') || 'en'
  var debug = urlParams.get('debug') || false
  var offsets;
  try {
    offsets = TOOLTIP_OFFSETS[id][language];
  } catch {
    console.warn(`[Flappy Bird Demo] text for ${id} has no offsets for ${language}`)
  }
  return offsets[direction] || 0;
}

export function getText(id) {
  var urlParams = new URLSearchParams(window.location.search);
  var language = urlParams.get('language') || 'en'
  var debug = urlParams.get('debug') || false
  return getTranslatedText(id, language, debug);
}

export function getOffsetX(id) {
  return getOffset(id, 'x');
}

export function getOffsetY(id) {
  return getOffset(id, 'y');
}
